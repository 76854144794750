import React from 'react';
import Typography from '@material-ui/core/Typography';
import styles from './ThankYouPage.module.scss';
import { graphql, Link, useStaticQuery } from 'gatsby';

const ThankYouPage = () => {
  const { bg } = useStaticQuery(graphql`
    query {
      bg: allFile(filter: { sourceInstanceName: { eq: "thank-you" } }) {
        nodes {
          name
          publicURL
        }
      }
    }
  `);

  const goBack = e => {
    e.preventDefault();

    if (window) {
      window.history.back();
    }
  };

  return (
    <div className={styles['thank-you']}>
      <Typography variant={'h1'} align={'center'} className={styles['title-mobile']} color={'secondary'}>Thank you</Typography>
      <div className={styles['thank-you__text']}>
        <Typography variant="h1">Thank you</Typography>
        <Typography variant={'h3'}>
          Your request has been submitted <br /> successfully and we will get in{' '}
          <br /> touch with you soon
        </Typography>

        <Link to={'/'}>go to homepage</Link>
        <Link to={'/contact-us'} onClick={goBack}>
          new message
        </Link>
      </div>
      <img src={bg.nodes[0].publicURL} alt="" />
    </div>
  );
};

export default ThankYouPage;
