import React from 'react';
import Layout from '../components/Layout';
import useSiteMetadata from '../hooks/use-site-metadata';
import ThankYouPage from '../components/ThankYouPage';

const ThankYouTemplate = ({ location }) => {
    const { subtitle: siteSubtitle } = useSiteMetadata();
    return (
        <Layout title="CyberVision - Thank you" location={location} hideBreadcrumbs description={siteSubtitle} >
            <ThankYouPage />
        </Layout>
    );
};

export default ThankYouTemplate
